import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { ACCESS_TOKEN_NAME } from "src/env"
import { QUERY_KEYS } from "src/server/queryHooks/queryKeys"
import VacancyService from "src/server/services/VacancyService"
import { CacheTime } from "src/utils/configs/apiCacheTime"
import Cookie from "src/utils/configs/Cookie"
import { IDataForVacancies } from "../../types/commonTypes"
import { IVacanciesCount } from "src/types/vacancyTypes"
import MainService from "src/server/services/MainService"

const useVacancy = () => {
  const router = useRouter()

  const vacanciesPage = router.query.page ? router.query.page : "1"
  const queryClient = useQueryClient()
  const accessToken = typeof window !== "undefined" ? Cookie.get(ACCESS_TOKEN_NAME) : null
  const haveToken = !!accessToken

  const vacancySearchQuery = useInfiniteQuery<any>({
    queryKey: [QUERY_KEYS.PUBLIC_VACANCY_SEARCH_V2, { ...router.query }],
    queryFn: ({ pageParam = 1 }) => getVacancySearchV2({ ...router.query, page: pageParam }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.current_page === lastPage.last_page) return undefined
      return lastPage.current_page + 1
    },
    enabled: router.pathname === "/vacancy"
  })

  const vacancySearchPaginationQuery = useQuery<any>({
    queryKey: [QUERY_KEYS.PUBLIC_VACANCY_PAGINATION_SEARCH_V2, { ...router.query }],
    queryFn: () => getVacanciesSearchV2({ ...router.query, count: 16, page: vacanciesPage }),
    enabled: router.pathname === "/vacancy"
  })

  const vacancyLatestQuery = useQuery<any>({
    queryKey: [QUERY_KEYS.PUBLIC_LATEST_VACANCIES, { ...router.query }],
    queryFn: () => getVacanciesSearchV2({ ...router.query, count: 12, newest: true }),
    enabled: true
  })

  const langSkillsCountriesQuery = useQuery<IDataForVacancies>({
    queryKey: [QUERY_KEYS.DATA_FOR_VACANCIES],
    queryFn: getLangsSkillsCountries,
    retry: 1,
    enabled: true
  })

  const oneVacancyQuery = useQuery({
    queryKey: [QUERY_KEYS.VACANCY_GET_BY_ID, Number(router.query.id)],
    queryFn: () => getSingleVacancy(Number(router.query.id)),
    retry: false,
    enabled: !!router.query.id && router.pathname.includes("/vacancy/[id]")
  })
  const similarVacancyQuery = useQuery({
    queryKey: [QUERY_KEYS.VACANCY_SIMILAR_GET_BY_ID, Number(router.query.id)],
    queryFn: () => getSimilarVacancy(Number(router.query.id)),
    enabled: !!router.query.id && router.pathname.includes("/vacancy/[id]")
  })

  const vacancyCountQuery = useQuery<IVacanciesCount>({
    queryKey: [QUERY_KEYS.PUBLIC_VACANCY_COUNT],
    queryFn: params => getVacancyCount(params),
    enabled: false
  })
  const vacancyGuestApplyMutation = useMutation({
    mutationKey: [QUERY_KEYS.POST_VACANCY_GUEST_APPLY],
    mutationFn: postVacancyGuestApply
  })
  const vacancyUserApplyMutation = useMutation({
    mutationKey: [QUERY_KEYS.POST_VACANCY_USER_APPLY],
    mutationFn: postVacancyUserApply,
    retry: 0
  })

  // Save/Apply Functionality
  const saveVacancyMutation = useMutation({
    mutationFn: (id: any) => postSaveVacancy(id),
    onSuccess: (data, variable) => {
      queryClient.setQueryData([QUERY_KEYS.GET_SAVED_APPLIED_STATUS], (oldData: any) => {
        const newStatus = { ...oldData }
        newStatus.saved[variable] = true
        return { ...newStatus }
      })
    }
  })

  const deleteSavedVacancyMutation = useMutation({
    mutationFn: (id: number) => deleteSavedVacancy(id),
    onSuccess(data, vacancyIdPayload, context) {
      queryClient.setQueryData([QUERY_KEYS.GET_SAVED_VACANCIES], (oldData: any) => {
        if (!oldData) return
        return oldData?.filter((vacancy: any) => vacancy.id !== vacancyIdPayload)
      })
      queryClient.setQueryData([QUERY_KEYS.GET_SAVED_APPLIED_STATUS], (oldData: any) => {
        if (!oldData) return
        const newStatus = { ...oldData }
        delete newStatus.saved[vacancyIdPayload]
        return newStatus
      })
    }
  })

  const appliedVacanciesQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_APPLIED_VACANCIES, { page: router.query.page || 1 }],
    queryFn: () => getAppliedVacancies({ page: parseInt(router.query.page as string) || 1 }),
    enabled: router.pathname.includes("/applied")
  })

  const savedVacanciesQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_SAVED_VACANCIES, { page: router.query.page || 1 }],
    queryFn: () => getSavedVacancies({ page: parseInt(router.query.page as string) || 1 }),
    enabled: router.pathname.includes("/saved")
  })

  const vacancySavedAppliedStatus = useQuery<{
    saved: Record<number, boolean>
    applied: Record<number, boolean>
  }>({
    queryKey: [QUERY_KEYS.GET_SAVED_APPLIED_STATUS],
    queryFn: getSavedAppliedStatus,
    enabled: haveToken,
    staleTime: Infinity,
    placeholderData: { saved: {}, applied: {} }
  })

  const getFilterQuery = useQuery({
    queryKey: [QUERY_KEYS.VACANCY_GET_FILTER],
    queryFn: getFilters,
    enabled: router.pathname.includes("/vacancies") && accessToken
  })

  const vacancyCountData = vacancyCountQuery.data

  return {
    vacancySearchQuery,
    vacancySearchPaginationQuery,
    vacancyCountData,
    vacancyCountQuery,
    langSkillsCountriesQuery,
    vacancyGuestApplyMutation,
    vacancyUserApplyMutation,
    appliedVacanciesQuery,
    savedVacanciesQuery,
    getFilterQuery,
    deleteSavedVacancyMutation,
    saveVacancyMutation,
    vacancySavedAppliedStatus,
    oneVacancyQuery,
    similarVacancyQuery,
    vacancyLatestQuery
  }
}

export const getLangsSkillsCountries = async () => {
  try {
    const response: any = await VacancyService.getDataForVacancies()

    return response.data
  } catch (e: any) {
    console.error("getLangsSkillsCountries search api error")
    throw e // Rethrow the error
  }
}

export const getSavedAppliedStatus = async () => {
  try {
    const response: any = await VacancyService.getSavedAppliedStatus()

    return response.data
  } catch (e: any) {
    console.error("getLangsSkillsCountries search api error")
    throw e // Rethrow the error
  }
}

export const getVacancySearch = async (params: any) => {
  try {
    const response: any = await VacancyService.getVacancySearch(params)

    return response.data
  } catch (e: any) {
    console.error("vacancy search api error", e)
    throw e // Rethrow the error
  }
}

export const getVacancySearchV2 = async (params: any) => {
  try {
    const response: any = await VacancyService.getVacancySearchV2(params)

    return response.data
  } catch (e: any) {
    console.error("vacancy search api error")
    throw e // Rethrow the error
  }
}

export const getVacanciesSearchV2 = async (params: any) => {
  try {
    const response: any = await VacancyService.getVacanciesSearchV2(params)

    return response.data
  } catch (e: any) {
    console.error("vacancy search api error")
    throw e // Rethrow the error
  }
}

export const getVacancyCount = async (params: any) => {
  try {
    const response: any = await VacancyService.getVacancyCount(params)

    return response.data
  } catch (e: any) {
    console.error("vacancy count api error")
    throw e // Rethrow the error
  }
}

export const increaseViewCount = async (params: any) => {
  try {
    const response: any = await VacancyService.increaseViewCount(params)

    return response.data
  } catch (e: any) {
    console.error("vacancy count api error")
    throw e // Rethrow the error
  }
}

export const postVacancyUserApply = async (body: { vacancy_id: number }) => {
  try {
    const response: any = await VacancyService.postVacancyUserApply(body)

    return response.data
  } catch (e: any) {
    console.error("postVacancyUserApply error", e)
    throw e // Rethrow the error
  }
}

export const getSingleVacancy = async (vacancy_id: number) => {
  try {
    const response: any = await VacancyService.getSingleVacancy(vacancy_id)

    return response.data.data
  } catch (e: any) {
    return null
    console.error("getSingleVacancy", e)
  }
}

export const getSimilarVacancy = async (vacancy_id: number) => {
  try {
    const response: any = await VacancyService.getSimilarVacancy(vacancy_id)

    return response.data
  } catch (e: any) {
    console.error("getSimilarVacancy", e)
  }
}

export const getFilters = async () => {
  try {
    const response: any = await VacancyService.getFilters()

    return response.data
  } catch (e: any) {
    console.error("getFilters api error")
    throw e
  }
}

export const addFilter = async (filters: any) => {
  try {
    const response: any = await VacancyService.addFilter(filters)

    return response.data
  } catch (e: any) {
    console.error("addFilter api error")
    throw e
  }
}

interface VacanciesApplyBody {
  vacancy_id: string
  name: string
  mobile: string
  email: string
  profession: string
  code: string
  cv: File
}
export const postVacancyGuestApply = async (data: VacanciesApplyBody) => {
  try {
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value)
      } else {
        formData.append(key, value.toString())
      }
    })

    const response: any = await VacancyService.postVacancyGuestApply(formData)

    return response.data
  } catch (e: any) {
    console.error("vacancy apply api error")
    throw e // Rethrow the error
  }
}

export const getAppliedVacancies = async (req?: { page?: number }) => {
  try {
    const response: any = await VacancyService.getAppliedVacancies(req)

    return response.data
  } catch (e: any) {
    console.error("vacancy apply api error")
    throw e // Rethrow the error
  }
}

export const getSavedVacancies = async (req?: { page?: number }) => {
  try {
    const response: any = await VacancyService.getSavedVacancies(req)

    return response.data
  } catch (e: any) {
    console.error("getSavedVacancies", e)
    throw e // Rethrow the error
  }
}

export const deleteSavedVacancy = async (id: number) => {
  try {
    const response: any = await VacancyService.deleteSavedVacancy(id)

    return response.data
  } catch (e: any) {
    console.error("deleteSavedVacancyApplications")
    throw e // Rethrow the error
  }
}

export const postSaveVacancy = async (id: number) => {
  try {
    const response: any = await VacancyService.postSaveVacancy(id)

    return response.data
  } catch (e: any) {
    console.error("error SaveVacancyApplications")
    throw e // Rethrow the error
  }
}

export default useVacancy
